<template>
  <div id="mainPreset">
    <v-menu
      class="main-presets"
      bottom
      offset-y
      nudge-bottom="8"
      content-class="menu"
      transition="scale-transition"
      v-model="computedOpen"
      :close-on-click="false"
      :close-on-content-click="false"
      v-click-outside="{
              handler: () => computedOpen = false,
              include: getNonClosingElementsOnclick
            }"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          data-cy="main-presets-button"
          color="primary"
          class="border-radius"
          height=32
          id="main-preset-button"
          small
          v-on="on"
        >
          <v-tooltip
            bottom
            open-delay="200"
            :disabled="computedOpen"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                class="d-flex"
                v-on="on"
                v-bind="attrs"
              >
                <v-icon small>{{$icon('i.BusinessDivisions')}}</v-icon>
                <main-preset-ref
                  :id="computedId"
                  class="preset-name mx-1"
                />
              </div>
            </template>
            <span>{{$t('t.MainPresets')}}</span>
          </v-tooltip>
          <template v-if="computedChanged && !saveMode">
            <v-btn
              v-if="canSave"
              color="success"
              class="mr-1"
              depressed
              rounded
              x-small
              @click.stop="saveChanges()"
              :disabled="computedSaving"
            >
              <v-icon>{{$icon('i.Checked')}}</v-icon>
            </v-btn>
            <v-btn
              data-cy="main-presets-revert-button"
              color="warning"
              depressed
              rounded
              x-small
              @click.stop="cancelChanges()"
              :disabled="computedCancelChanges"
            >
              <v-icon>{{$icon('i.Undo')}}</v-icon>
            </v-btn>
          </template>
        </v-btn>
      </template>
      <v-card
        data-cy="main-presets-list"
        class="d-flex flex-column pt-2"
        id="main-preset-menu-content"
      >
        <v-expand-transition>
          <div
            v-show="!saveMode"
            class="overflow-y-hidden pa-2 text-center"
          >
            <paginated-list-preset
              :items="search.items"
              item-key="id"
              :page-size="pageSize"
              :search-text.sync="search.searchText"
              :selected-page.sync="search.page"
              :total-items="search.resultCount"
              @update:selected="selectPreset"
            >
              <template v-slot:item-content="{item}">
                <document-picker-list-item-ref
                  v-if="item.id"
                  :item="item"
                  class="clickable"
                  :show-icon="false"
                  :show-detail="false"
                />
              </template>
              <template v-slot:item-action="{item}">
                <v-list-item-action
                  v-if="hasVisibleActions(item)"
                  class="my-0"
                >
                  <quick-actions
                    content-class="list-item-action"
                    :attach="false"
                    :actions="actions"
                    :item="item"
                    z-index=9
                  />
                </v-list-item-action>
              </template>
            </paginated-list-preset>
            <v-btn
              :disabled="!computedChanged"
              class="border-radius mt-2"
              @click.stop="saveMode = true"
              color="primary"
              depressed
              small
            >
              <v-icon class="ml-n2 mr-1">{{$icon('i.PlusCircle')}}</v-icon>{{$t('t.Add')}}
            </v-btn>
          </div>
        </v-expand-transition>
        <v-expand-transition>
          <div v-show="saveMode">
            <v-form
              @submit="form && saveMode && save(name)"
              v-model="form"
            >
              <translations
                class="px-4"
                is-new
                :translations.sync="name"
                :rules="rules"
                ref="translations"
              />
              <div class="d-flex pa-4 pt-0">
                <v-spacer />
                <v-btn
                  fab
                  small
                  color="success"
                  @click.native.stop="save(name)"
                  :disabled="!form || !saveMode"
                >
                  <v-icon ref="check">{{$icon('i.Checked')}}</v-icon>
                </v-btn>
              </div>
            </v-form>
          </div>
        </v-expand-transition>
      </v-card>
    </v-menu>

    <v-dialog
      v-model="showConfirmActionPopUp"
      max-width=500
      @click:outside.stop="cancelAction()"
    >
      <v-card id="delete-preset-dialog">
        <v-card-title>{{$t('t.PromptConfirmation')}}</v-card-title>
        <v-card-text>
          <span class="mr-1">{{$t(actionMessage, {title: computedActionTargetName})}}</span>
          <template v-if="presetActionTarget.isPublic">
            <br />
            <span class="font-weight-medium error--text">{{$t('t.ThisWillImpactAllUsers')}}</span>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="action()">{{$t('t.Yes')}}</v-btn>
          <v-btn
            color="primary"
            @click="cancelAction()"
          >{{$t('t.No')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Search from '@/pages/search/controllers/search'
import { MainPresetSelector } from '@/wasm/pkg'
import ClientCache from '@/mixins/client-cache'

export default {
  mixins: [ClientCache],
  components: {
    DocumentPickerListItemRef: () => import('@/components/document-picker-list-item-ref'),
    MainPresetRef: () => import('@/components/documents-ref/main-preset-ref'),
    PaginatedListPreset: () => import('./paginated-list-preset.vue'),
    QuickActions: () => import('@/components/quick-actions'),
    Translations: () => import('@/components/translations')
  },
  beforeDestroy () {
    this.search = undefined
  },
  computed: {
    actions () {
      return [
        {
          text: this.$t('t.Rename'),
          icon: this.$icon('i.Pencil'),
          action: item => this.renamePreset(item),
          isVisible: item => item.extra.canWrite
        },
        {
          text: this.$t('t.MakePublic'),
          icon: this.$icon('i.Eye'),
          action: item => this.makePresetPublic(item),
          isVisible: item => item.extra.canPublish
        },
        {
          text: this.$t('t.Delete'),
          icon: this.$icon('i.Delete'),
          action: item => this.deletePreset(item),
          isVisible: item => item.extra.canDelete
        }
      ]
    },
    cacheType () {
      return ClientCache.CacheType.MainPresetDetail
    },
    canSave () {
      return this.cache?.doc?.canWrite ?? false
    },
    computedActionTargetName () {
      switch (typeof this.presetActionTarget?.name) {
        case 'string':
          return this.presetActionTarget?.name
        case 'object':
          return this.presetActionTarget?.name?.[this.$store.getters.currentUser.culture]
        default:
          return ''
      }
    },
    computedChanged () {
      return !!this.selectorObs.changed
    },
    computedId: {
      get () { return this.selectedPresetid },
      set (v) {
        this.selectedPresetid = v
        this.id = v
        this.search.searchedIds.clear().exclude([this.computedId])
        this.$waitFor(() => typeof this.cache?.doc?.isSystemDefined !== 'undefined').then(() => { this.selectedPresetIssystem = this.cache?.doc?.isSystemDefined })
        MainPresetSelector.loadPreset(v)
      }
    },
    computedOpen: {
      get () { return this.dataOpen },
      set (v) {
        if (this.dataOpen !== v) {
          this.dataOpen = v
          if (v) {
            this.search.execute()
          } else {
            this.search.clearText()
            this.$refs?.translations?.closeTranslations()
            this.closeSaveMode()
          }
        }
      }
    },
    computedSaving () {
      return this.selectorObs.saving
    },
    computedCancelChanges () {
      return this.selectorObs.saving
    },
    pageSize () {
      return 10
    },
    rules () {
      return [
        v => !!v || this.$t('t.IsRequired'),
        v => v.length <= 100 || this.$t('t.MaxLength', { value: 100 })
      ]
    }
  },
  data () {
    return {
      action: () => { },
      actionMessage: '',
      currentPage: 1,
      dataOpen: false,
      dataSearch: '',
      form: false,
      id: null,
      name: {},
      presetActionTarget: {},
      renameMode: false,
      saveMode: false,
      search: new Search().chain(s => s.searchedDocumentTypes.include(['main-presets'])),
      selectedPresetid: null,
      selectedPresetIssystem: true,
      selectorObs: MainPresetSelector.getObs(),
      showConfirmActionPopUp: false,
      tab: {}
    }
  },
  methods: {
    cancelAction () {
      this.action = () => { }
      this.showConfirmActionPopUp = false
      this.presetActionTarget = {}
      this.actionMessage = ''
      this.id = undefined
    },
    cancelChanges () {
      MainPresetSelector.cancelChanges()
    },
    closeSaveMode () {
      this.name = {}
      this.form = true
      this.saveMode = false
      this.renameMode = false
    },
    deletePreset (preset) {
      this.$set(preset, 'isPublic', preset.extra.isPublic)

      this.presetActionTarget = preset
      this.action = this.doDelete
      this.actionMessage = 't.DeleteConfirmation'
      this.showConfirmActionPopUp = true
    },
    doDelete () {
      if (this.presetActionTarget) {
        MainPresetSelector.deleteMainPreset(this.presetActionTarget.id).then(() => {
          this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.DeleteConfirmation'))
        }).finally(() => {
          this.cancelAction()
          this.search.execute()
        })
      }
    },
    async doMakePresetPublic () {
      this.id = this.presetActionTarget.id
      const name = await this.$waitFor(() => this.cache?.doc?.name)
      MainPresetSelector.makeMainPresetPublic(this.presetActionTarget.id, name).then(() => {
        this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.SaveConfirmation'))
      }).finally(() => {
        this.cancelAction()
        this.search.execute()
        this.id = undefined
      })
    },
    async doRenamePreset () {
      this.showConfirmActionPopUp = false
      this.id = this.presetActionTarget.id
      this.name = await this.$waitFor(() => this.cache?.doc?.name)
      this.renameMode = true
      this.saveMode = true
      this.presetActionTarget = {}
    },
    async doSaveChanges () {
      await this.save(this.presetActionTarget.name, false)
      this.cancelAction()
    },
    getNonClosingElementsOnclick () {
      return [
        document.getElementById('delete-preset-dialog'),
        document.getElementById('main-preset-button'),
        document.getElementById('main-preset-menu-content'),
        ...document.getElementsByClassName('list-item-action'),
        ...document.getElementsByClassName('v-overlay')
      ].filter(e => e)
    },
    hasVisibleActions (item) {
      return this.actions.some(action => action.isVisible(item))
    },
    makePresetPublic (preset) {
      this.presetActionTarget = preset
      this.action = this.doMakePresetPublic
      this.actionMessage = 't.MakePublicConfirmation'
      this.showConfirmActionPopUp = true
    },
    async renamePreset (preset) {
      this.presetActionTarget = preset
      this.$set(preset, 'isPublic', preset.extra.isPublic)

      if (!preset.isPublic) {
        this.doRenamePreset()
      } else {
        this.action = this.doRenamePreset
        this.actionMessage = 't.RenameConfirmation'
        this.showConfirmActionPopUp = true
      }
    },
    async saveChanges () {
      this.id = this.computedId
      this.presetActionTarget = await this.$waitFor(() => this.cache?.doc)
      this.action = this.doSaveChanges
      this.actionMessage = 't.SaveConfirmation'
      this.showConfirmActionPopUp = true
    },
    save (name, isNew = !this.renameMode) {
      (this.renameMode ? MainPresetSelector.saveMainPresetDetail(this.id, name) : MainPresetSelector.saveMainPresetParts(name, isNew))
        .then(r => {
          if (r && this.computedId !== r.id) {
            this.computedId = r.id
          }
          this.computedOpen = false
          this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.SaveConfirmation'))
          this.refreshCache()
        })
        .catch(e => {
          e = JSON.parse(e)
          const error = e.conflictCultureNames
            ? this.$t('t.ConflictCultureNames')
            : this.$t('t.Snackbar.SaveError', { title: this.truncateWithEllipsis(e.message) })
          this.$store.dispatch('showErrorSnackbar', error)
        })
    },
    selectPreset (item) {
      if (!item?.id) { return }
      this.computedId = item.id
      this.computedOpen = false
      if (this.$store.getters.currentUser) {
        this.$store.dispatch('setCurrentUser', { mainPresetId: item.id })
      }
      this.closeSaveMode()
    }
  },
  mounted () {
    this.computedId = this.$store.getters.currentUser?.mainPresetId ?? '9357917b-9be9-414b-b370-0f308fe9e4d0'
  },
  watch: {
    computedItems () {
      this.$triggerResize()
    }
  }
}
</script>

<style lang="stylus" scoped>
.preset-name
  max-width 130px
</style>
